import * as React from "react";
import Header from "../components/header";
import Newsletter from "../components/newsletter";
import Footer from "../components/Footer";
import SEO from "../components/SEO";
import BannerEffect from "../themes/custom/jobs_front/assets/img/bannerEffect.png";
import WorkEffect from "../themes/custom/jobs_front/assets/img/effetWork.png";
import TunisiaJobs from "../assets/img/tunisiaJobs5-1.png";
import Work from "../assets/img/work-1.png";
import Arrow from "../themes/custom/jobs_front/assets/img/arrowRight2.png";
const AgricultureAndAgribusiness = () => {
  return (
    <>
      <SEO
        title="Agriculture and Agribusines | Visit Tunisia Projects"
        description="Agriculture and Agribusiness"
      />
      <Header />
      <main role="main">
        <div className="container-fluid">
          <div className="region region-banner">
            <div
              id="block-agricultureagribusiness"
              className="block block-block-content block-block-content28fa2106-f128-4ead-af2c-131f1bc9b101"
            >
              <div className="intern-banner">
                <div className="overlay">
                  <img src={BannerEffect} alt="" />
                </div>
                <img src={TunisiaJobs} alt="" />
                <div className="caption">
                  <h1 className="animate__animated animate__fadeInUp animate__slow">
                    <div className="field field--name-field-titre field--type-string field--label-hidden field__item">
                      Agriculture and Agribusiness
                    </div>
                  </h1>
                  <div className="animate__animated animate__fadeInUp animate__slow animate__delay-1s">
                    <div className="clearfix text-formatted field field--name-body field--type-text-with-summary field--label-hidden field__item">
                      <p>
                        Helping Tunisian companies access <span>export</span>{" "}
                        markets
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="region region-content">
            <div data-drupal-messages-fallback className="hidden" />
            <div
              id="block-jobs-front-content"
              className="block block-system block-system-main-block"
            >
              <article
                data-history-node-id={24}
                role="article"
                about="/en/Agriculture-and-agribusiness"
                className="node node--type-activities node--promoted node--view-mode-full"
              >
                <div className="node__content">
                  <div className="field field--name-field-bricks field--type-bricks field--label-hidden field__items">
                    <div>
                      <section className="sector-vision">
                        <div className="container">
                          <div className="row">
                            <div className="card-wrapper">
                              <div
                                className="card aos-animate"
                                data-aos="fade-up"
                                data-aos-easing="ease-in-back"
                                data-aos-delay={200}
                              >
                                <div className="inner-card">
                                  <h3>The Sector</h3>
                                  <p>
                                    Agribusiness is the third largest
                                    manufacturing sector in Tunisia, accounting
                                    for almost 10% of GDP and $1.95 billion in
                                    exports, reaching 138 countries worldwide.
                                    The sector includes a wide range of
                                    enterprises, from thousands of small
                                    producers to large, sophisticated
                                    enterprises throughout Tunisia.
                                  </p>
                                </div>
                              </div>
                              <div
                                className="card aos-animate"
                                data-aos="fade-up"
                                data-aos-easing="ease-in-back"
                                data-aos-delay
                              >
                                <div className="inner-card">
                                  <h3>JOBS’ Vision and Strategy</h3>
                                  <p>
                                    JOBS is supporting both small-scale
                                    producers and SMEs to increase revenues and
                                    create economic opportunities throughout
                                    Tunisia by strengthening supply chains,
                                    helping SMEs add value to their production,
                                    and increasing access to export markets.
                                    JOBS reaches many farmers, especially in
                                    underserved regions, by partnering with
                                    Mutual Agricultural Services Companies
                                    (SMSA). Through these efforts, JOBS
                                    incorporates small farmers into the formal
                                    sector, increasing employment, revenues, and
                                    investment in underserved regions.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      <section className="work" id="assistance">
                        <div className="brick brick--type--work-section brick--id--58 eck-entity container">
                          <h2
                            className="title-left aos-animate"
                            data-aos="fade-up"
                          >
                            <div className="field field--name-field-subtitle field--type-string field--label-hidden field__item">
                              Our Work
                            </div>
                          </h2>
                          <div
                            className="work-text aos-animate"
                            data-aos="fade-up"
                          >
                            <p>&nbsp;</p>
                            <p>
                              Agribusiness is one JOBS’ key areas of support,
                              allowing JOBS to reach thousands of small farmers
                              in underserved areas where few job options exist.
                              JOBS reaches producers in these regions from its
                              regional offices in Beja, Kef, Gafsa, and Gabes
                              that work predominately with agribusiness and
                              agricultural companies. More than 75% of our work
                              in the sector is in underserved regions and nearly
                              30% of jobs and sales created with the project are
                              in the agribusiness and agriculture sector.
                            </p>
                            <p>&nbsp;</p>
                            <p>
                              The project also supports agricultural value chain
                              assistance, especially linkages between bigger
                              companies and small producers. In 2020, JOBS added
                              SMSA, or agricultural cooperatives, as a priority
                              for direct assistance through partnership
                              agreements. While we work with SMEs producing all
                              types of agricultural products, our project
                              targets Tunisia’s leading agricultural products
                              including seafood and dates.
                            </p>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                          </div>
                          <div className="our-work-carousel">
                            <div className="owl-carousel owl-theme">
                              <div
                                className="item work-card aos-animate"
                                data-aos="zoom-in-up"
                                data-aos-anchor-placement="top-center"
                                data-aos-easing="ease-in-back"
                                data-aos-delay={100}
                              >
                                <div className="overlay">
                                  <img src={WorkEffect} alt="" />
                                </div>
                                <img src={Work} alt="" />
                                <div className="card-text">
                                  <h3>Date Producers and Exporters</h3>
                                  <div className="card-info justify-content-between align-items-center">
                                    <span>
                                      Helping date producers move up the supply
                                      chain
                                    </span>
                                    <a
                                      href="../stories/c648c93e971c43098a2d9498fa4948bc.html"
                                      target="_blank"
                                    >
                                      <img src={Arrow} alt="" />
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="item work-card aos-animate"
                                data-aos="zoom-in-up"
                                data-aos-anchor-placement="top-center"
                                data-aos-easing="ease-in-back"
                                data-aos-delay={200}
                              >
                                <div className="overlay">
                                  <img
                                    src="../themes/custom/jobs_front/assets/img/effetWork.png"
                                    alt=""
                                  />
                                </div>
                                <img
                                  src="../sites/default/files/2021-06/work-2.png"
                                  alt=""
                                />
                                <div className="card-text">
                                  <h3>Blue Crab and Fisheries</h3>
                                  <div className="card-info justify-content-between align-items-center">
                                    <span>
                                      Identifying export opportunities
                                    </span>
                                    <a
                                      href="../stories/18bc9f998a5542f4a249d8d1b46e0b3f.html"
                                      target="_blank"
                                    >
                                      <img
                                        src="../themes/custom/jobs_front/assets/img/arrowRight2.png"
                                        alt=""
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="item work-card aos-animate"
                                data-aos="zoom-in-up"
                                data-aos-anchor-placement="top-center"
                                data-aos-easing="ease-in-back"
                                data-aos-delay={300}
                              >
                                <div className="overlay">
                                  <img
                                    src="../themes/custom/jobs_front/assets/img/effetWork.png"
                                    alt=""
                                  />
                                </div>
                                <img
                                  src="../sites/default/files/2021-06/work-3.png"
                                  alt=""
                                />
                                <div className="card-text">
                                  <h3>Olive Oil</h3>
                                  <div className="card-info justify-content-between align-items-center">
                                    <span>
                                      Promoting Tunisian olive oil abroad{" "}
                                    </span>
                                    <a
                                      href="../stories/3c3ebe5b74ac41839061969dd8caf6ab.html"
                                      target="_blank"
                                    >
                                      <img
                                        src="../themes/custom/jobs_front/assets/img/arrowRight2.png"
                                        alt=""
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="item work-card aos-animate"
                                data-aos="zoom-in-up"
                                data-aos-anchor-placement="top-center"
                                data-aos-easing="ease-in-back"
                                data-aos-delay={400}
                              >
                                <div className="overlay">
                                  <img
                                    src="../themes/custom/jobs_front/assets/img/effetWork.png"
                                    alt=""
                                  />
                                </div>
                                <img
                                  src="../sites/default/files/2021-06/work-4.png"
                                  alt=""
                                />
                                <div className="card-text">
                                  <h3>SMSAs and Supply Chains</h3>
                                  <div className="card-info justify-content-between align-items-center">
                                    <span>
                                      Reaching small-scale farmers through
                                      support to SMSAs
                                    </span>
                                    <a
                                      href="../stories/ec0605e53e59454d8d974d2a1eeaa1fd.html"
                                      target="_blank"
                                    >
                                      <img
                                        src="../themes/custom/jobs_front/assets/img/arrowRight2.png"
                                        alt=""
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      <section
                        className="impact aos-animate"
                        data-aos="fade-up"
                      >
                        <div className="brick brick--type--impact brick--id--17 eck-entity container">
                          <span className="title-left">
                            <div className="field field--name-field-subtitle field--type-string field--label-hidden field__item">
                              Since its inception, Tunisia JOBS has :{" "}
                            </div>
                          </span>
                          <h2></h2>
                          <ul className="pl-0">
                            <li>
                              <span>
                                Delivered assistance to approximately 4,981
                                agribusiness companies and small farmers
                              </span>
                            </li>
                            <li>
                              <span>Created more than 9,115 jobs</span>
                            </li>
                            <li>
                              <span>
                                Helped companies increase sales and exports by
                                over TND 470 million
                              </span>
                            </li>
                            <li>
                              <span>Secured 90 million TND in investments</span>
                            </li>
                          </ul>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </main>
      <Newsletter />
      <Footer />
    </>
  );
};
export default AgricultureAndAgribusiness;
